<template>
  <div class="container">
    <div class="row">
      <div class="instruction">
        <div class="row">
          <div class="instruction-logo">
            <v-img src="../assets/images/logo-01.svg"></v-img>
          </div>
        </div>
        <div  v-if="Number(orderId) > 0" class="row">
          <div  class="instruction_title">Your book(s) will be downloaded after {{countDown}} second. If the download does not start automatically, please go to your
            <router-link :to="'/profile/my-orders-history'">personal account</router-link> and download them manually.
          </div>
        </div>
        <div class="row">
          <div class="instruction_title">HOW TO DOWNLOAD YOUR EBOOK</div>
        </div>
        <div class="row pt-4">
          <div class="instruction_item">
            <div class="instruction_item_left">
              <div class="instruction_title__circle">1</div>
            </div>
            <div class="instruction_item_right">
              <div class="instruction_item_title">RECEIVE YOUR ACSM FILE</div>
              <p>
                After purchasing an eBook from DigiThis, you will automatically receive an ACSM
                file. Do not open this file just yet, but make sure that you did receive your document
              </p>
            </div>
          </div>
        </div>
        <div class="row pt-2">
          <div class="instruction_item">
            <div class="instruction_item_left">
              <div class="instruction_title__circle">2</div>
            </div>
            <div class="instruction_item_right">
              <div class="instruction_item_title">
                CREATE YOUR FREE ADOBE ACCOUNT
                <a href="https://helpx.adobe.com/manage-account/using/create-update-adobe-id.html" target="_blank">HERE</a>
              </div>
              <p>This free account will be used to access all of your eBooks.</p>
            </div>
          </div>
        </div>
        <div class="row pt-2">
          <div class="instruction_item">
            <div class="instruction_item_left">
              <div class="instruction_title__circle">
                <div class="instruction_title__circle">3</div>
              </div>
            </div>
            <div class="instruction_item_right">
              <div class="instruction_item_title">DOWNLOAD YOUR E-READER APP</div>
              <p>
                Now it's time to get your e-reader set up! Your ACSM file will allow you to use one of
                the following e-readers to access your purchased eBook:
              </p>
            </div>
          </div>
          <div class="instruction_item_downloads">
            <div class="instruction_item_downloads_item">
              <div class="instruction_item_downloads_img">
                <v-img src="../assets/images/bluefire-reader-logo.png"></v-img>
              </div>
              <div class="instruction_item_downloads_title">BLUEFIRE READER</div>
              <p class="instruction_item_downloads_content">
                Bluefire reader is the suggested reader for users who have purchased an EPUB book.
                This reader is the more user friendly option and is free to use on Windows devices, but requires
                payment on Apple IOS and Android devices.
              </p>
              <div class="instruction_item_downloads_sug">SUGGESTED FOR:</div>
              <ul class="instruction_item_downloads_list">
                <li>EPUB documents/ Formats</li>
                <li>Desktop Users</li>
                <li>Apple Users</li>
              </ul>
              <div class="instruction_item_downloads_btn">
                <a
                  href="https://www.bluefirereader.com/bluefire-reader.html"
                  target="_blank"
                >DOWNLOAD</a>
              </div>
            </div>
            <div class="instruction_item_downloads_item">
              <div class="instruction_item_downloads_img">
                <v-img src="../assets/images/adobe-reader-logo.png"></v-img>
              </div>
              <div class="instruction_item_downloads_title">ADOBE DIGITAL EDITIONS</div>
              <p class="instruction_item_downloads_content">
                Adobe Digital Editions is the default ACSM file reader for eBooks and is directly supported by Adobe
                and the Adobe Content Server. This reader is suggested for PDF and static EPUB documents. This reader
                is free on all devices
              </p>
              <div class="instruction_item_downloads_sug">SUGGESTED FOR:</div>
              <ul class="instruction_item_downloads_list" style="margin-bottom: 3.5rem">
                <li>PDF documents/ Formats</li>
              </ul>
              <div class="instruction_item_downloads_btn">
                <a
                  href="https://www.adobe.com/ru/solutions/ebook/digital-editions/download.html"
                  target="_blank"
                  class
                >DOWNLOAD</a>
              </div>
            </div>
          </div>
          <p class="instruction_item_downloads_desc">
            Users who are planning on only using Android devices are encouraged to use the
            <a
              href="https://play.google.com/store/apps/details?id=com.aldiko.android&hl=us&gl=US"
            >Aldiko app</a>
          </p>
        </div>
        <div class="row pt-2">
          <div class="instruction_item">
            <div class="instruction_item_left">
              <div class="instruction_title__circle">4</div>
            </div>
            <div class="instruction_item_right">
              <div class="instruction_item_title">OPEN YOUR ACSM FILE</div>
              <p>
                After opening your ACSM File, your eBook should start downloading and become available on your
                e-reader library.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <v-container>
    <v-row>
      <v-col cols="12">
        <h1>{{ title }}</h1>
        <div v-html="body"></div>
      </v-col>
    </v-row>
  </v-container>-->
</template>

<script>
import Page from "@/models/Page";
import axios from "axios";
export default {
  name: "Instruction page",
  data() {
    return {
      countDown: 10,
      display:'none',
      title: "",
      body: "",
        orderId: null
    };
  },
  mounted() {
    this.fetchPage();
    this.downloadBook()
  },
  methods: {
    fetchPage() {
      Page.where("slug", "instruction")
        .get()
        .then(ApiResponse => {
          let response = ApiResponse.getData();
          if (response[0] !== undefined) {
            this.body = response[0].body;
            this.title = response[0].title;
          }
        });
    },
      downloadBook() {
          try {
              this.orderId = decodeURIComponent(atob(this.$route.query.id).split('').map(function(c) {
                  return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
              }).join(''));
          } catch (e) {}

          if (Number(this.orderId) > 0) {
              if (this.$route.query.id) {
                  this.display = 'flex';

                  let interval = setInterval(() => {
                      --this.countDown;
                  }, 1000)

                  setTimeout(() => {
                      clearInterval(interval)

                      axios.post('/api/download', {
                          id: this.orderId
                      })
                          .then(response => response.getData())
                          .then(res => {
                              if(res.status === 2){
                                  res.items.map((e, index) => {
                                      if (e.download_link){
                                          setTimeout(() => {
                                              let fileLink = document.createElement('a');
                                              fileLink.href = e.download_link;
                                              fileLink.setAttribute('download', 'download');
                                              fileLink.setAttribute('id', 'download');
                                              document.body.appendChild(fileLink);
                                              fileLink.click();
                                              let a = document.getElementById('download')
                                              a.remove()
                                          }, index * 2000)
                                      }
                                  })
                              }
                          })
                  }, 10000)
              }
          }
      },
  },
};
</script>

<style lang="sass">
.instruction
  display: flex
  flex-direction: column
  max-width: 61rem
  padding-bottom: 5rem
  margin: 0 auto
  &-logo
    width: 33rem
    margin: 0 auto
    & img
      width: 100%
  &_title
    width: 100%
    margin-top: 3.5rem
    margin-bottom: 6rem
    text-align: center
    font-size: 3rem
    font-weight: 700
    color: #302e2e
    &__circle
      width: 3.7rem
      height: 3.7rem
      margin-right: 2rem
      border-radius: 50%
      background: #0d346d
      color: #ffffff
      font-size: 2.4rem
      font-weight: 700
      text-align: center
  &_item
    display: flex
    padding-bottom: 3rem
    &_title
      padding-bottom: 1.6rem
      font-size: 2.8rem
      font-weight: 700
      color: #0d346d
      line-height: 1
    &_right
      display: flex
      flex-direction: column
      font-size: 1.8rem
    &_downloads
      display: flex
      &_item
        font-size: 1.2rem
        text-align: center
      &_img
        width: 6rem
        height: 6rem
        margin: 0 auto
      &_title
        width: 100%
        padding-top: 1.2rem
        padding-bottom: 1.2rem
        font-size: 2rem
        font-weight: 700
        color: #0d346d
        font-weight: 700
        text-align: center
      &_content
        width: 90%
        margin: 0 auto
      &_sug
        width: 100%
        padding-top: 1.6rem
        padding-bottom: 1.6rem
        font-size: 1.4rem
        font-weight: 700
        color: #0d346d
        text-align: center
      &_list
        width: 65%
        margin: 0 auto
        text-align: left
      &_btn a
        display: inline-block
        margin-top: 4rem
        margin-bottom: 3rem
        padding: 1rem .8rem
        background: #0d346d
        text-align: center
        color: #fff
        border-radius: 1.2rem
        text-decoration: none
        outline: none
      &_desc
        width: 65%
        margin: 0 auto
        font-size: 1.2rem
        color: #000000
        font-style: italic
        text-align: center
        & a
          color: #000
          font-weight: 700
        & p
          margin-bottom: 3.5rem
@media screen and (max-width: 991px)
  .instruction
    padding: 12px
    margin: 12px
    &-logo
      width: 15rem
    &_title
      font-size: 2rem
      &__circle
        font-size: 1.8rem
        line-height: 2
    &_item
      position: relative
      flex-direction: column
      &_left
        position: absolute
        left: 0
        top: 0

      &_title
        margin-left: 4.3rem
        font-size: 1.9rem
      &_downloads
        flex-direction: column
</style>
